/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Article,
  Category,
  ImageSet,
  KeywordData,
  Product,
} from "../../types/type";
import { getUuid } from "../../utils/common-util";
import axios from "axios";
import Select from "react-select";
import { getKeywords } from "../../api/keywords.api";
import { getApiClient } from "../../api/client.api";
import { UX_EVALUATION } from "../../types/enum";
interface ArticleFormProps {
  onAddArticle: (article: Article) => void;
  categories: KeywordData[];
}

const ArticleForm: React.FC<{ onAddArticle: (article: Article) => void }> = ({
  onAddArticle,
}) => {
  const apiClient = getApiClient();
  const currentDate = new Date();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [keywordId, setKeywordId] = useState<string>();
  const [keywordKorName, setKeywordKorName] = useState<string>();
  const [images, setImages] = useState<ImageSet[]>([
    { imageUrl: "", description: "" },
  ]); // 이미지 세트 추가
  const [date, setDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`,
  );
  const [authorName, setAuthorName] = useState("");
  const [authorEmail, setAuthorEmail] = useState("");
  const [authorInsta, setAuthorInsta] = useState("");
  const [authorLinkedIn, setAuthorLinkedIn] = useState("");
  const [articleLinks, setArticleLinks] = useState<string[]>([""]);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string | undefined>(
    "",
  );
  const [product, setProduct] = useState<string>("");
  const [productImage, setProductImage] = useState<string>("");
  const [categories, setCategories] = useState<Category[]>([]);
  const [availableKeywords, setAvailableKeywords] = useState<
    KeywordData[] | undefined
  >([]);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined,
  );

  const [uxEvaluationTag, setUxEvaluationTag] = useState<string>();
  const [categoryTag, setCategoryTag] = useState<string[]>([]);
  const [patternTag, setPatternTag] = useState<string[]>([]);
  const [uiComponentTag, setUIComponentTag] = useState<string[]>([]);

  const [allAuthorsList, setAllAuthorsList] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState("");

  useEffect(() => {
    const fetchCategoriesAndKeywords = async () => {
      try {
        const [catResponse, keywordResponse, proResponse, authorResponse] =
          await Promise.all([
            axios.get("https://uxpirates.xyz/api/categories"),
            axios.get("https://uxpirates.xyz/api/keywords"),
            axios.get("https://uxpirates.xyz/api/products"),
            axios.get("https://uxpirates.xyz/api/author"),
          ]);
        setCategories(catResponse.data);
        setAvailableKeywords(keywordResponse.data);
        setProducts(proResponse.data);
        setAllAuthorsList(authorResponse.data);
      } catch (error) {
        console.error("Failed to fetch categories and keywords:", error);
      }
    };

    fetchCategoriesAndKeywords();
  }, []);

  useEffect(() => {
    getKeywords(apiClient).then((res) => {
      setAvailableKeywords(
        res.data?.filter((el) => el.categoryId === selectedCategory),
      );
    });
  }, [selectedCategory]);

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(e.target.value);
  };
  const handleProductChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProduct(e.target.value);
    const selectedProductElement = products.filter(
      (el) => el.id === e.target.value,
    )[0];
    setProduct(selectedProductElement.name!);
    setProductImage(selectedProductElement.imageUrl!);
  };
  const handleKeywordChange = (selectedOptions: any) => {
    setKeywordId(selectedOptions.value.id);
    setKeywordKorName(selectedOptions.value.koreaName);
    setContent(selectedOptions.value.desc);
    setArticleLinks(selectedOptions.value.studyLink);
  };

  const handleUxEvaluationTagChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setUxEvaluationTag(e.target.value);
  };

  const handleAddArticleLink = () => {
    setArticleLinks([...articleLinks, ""]);
  };

  const handleRemoveArticleLink = (index: number) => {
    setArticleLinks(articleLinks.filter((_, i) => i !== index));
  };

  const handleArticleLinkChange = (index: number, value: string) => {
    const newLinks = [...articleLinks];
    newLinks[index] = value;
    setArticleLinks(newLinks);
  };

  const handleTagChange = (
    setter: React.Dispatch<React.SetStateAction<string[]>>,
    tags: string[],
  ) => {
    setter(tags);
  };

  const handleAddImageSet = () => {
    setImages([...images, { imageUrl: "", description: "" }]);
  };

  const handleRemoveImageSet = (index: number) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleImageChange = (index: number, value: string) => {
    const newImages = [...images];
    newImages[index].imageUrl = value;
    setImages(newImages);
  };

  const handleDescriptionChange = (index: number, value: string) => {
    const newImages = [...images];
    newImages[index].description = value;
    setImages(newImages);
  };

  // 작성자 선택 시 세부 정보 업데이트
  const handleAuthorChange = (event) => {
    const authorId = event.target.value;
    setSelectedAuthor(authorId);

    const author = allAuthorsList.find((author) => author.id === authorId);
    if (author) {
      setAuthorName(author.authorName);
      setAuthorEmail(author.authorEmail);
      setAuthorInsta(author.authorInsta);
      setAuthorLinkedIn(author.authorLinkedin);
    } else {
      setAuthorName("");
      setAuthorEmail("");
      setAuthorInsta("");
      setAuthorLinkedIn("");
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newArticle: Article = {
      id: getUuid(),
      title,
      generateTime: new Date().toISOString(),
      content,
      keywordKorName: keywordKorName,
      keywordId: keywordId,
      uxEvaluation: uxEvaluationTag,
      images: images, // 이미지와 설명을 함께 저장
      author: authorName,
      articleLink: articleLinks,
      product,
      productId: selectedProduct,
      productImage,
      authorEmail,
      authorInsta,
      authorLinkedIn,
    };
    onAddArticle(newArticle);
  };

  return (
    <div className="article-form">
      <div className="title">글 쓰기</div>
      <form onSubmit={handleSubmit}>
        <label>
          제목
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="제목을 입력하세요."
            required
          />
        </label>
        {/*<label>*/}
        {/*  product*/}
        {/*  <input*/}
        {/*    type="text"*/}
        {/*    value={product}*/}
        {/*    onChange={(e) => setProduct(e.target.value)}*/}
        {/*    placeholder="서비스명을 입력하세요. ex) 네이버페이"*/}
        {/*    required*/}
        {/*  />*/}
        {/*</label>*/}
        {/*<label>*/}
        {/*  product 이미지*/}
        {/*  <input*/}
        {/*    type="url"*/}
        {/*    value={productImage}*/}
        {/*    onChange={(e) => setProductImage(e.target.value)}*/}
        {/*    placeholder="이미지 URL"*/}
        {/*    required*/}
        {/*  />*/}
        {/*</label>*/}
        <label>
          Product:
          <select onChange={handleProductChange} required>
            <option value="">Select Product</option>
            {products.map((pro) => (
              <option key={pro.id} value={pro.id}>
                {pro.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Category:
          <select onChange={handleCategoryChange} required>
            <option value="">Select Category</option>
            {categories.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.koreaName}
              </option>
            ))}
          </select>
        </label>

        <label>
          Keywords:
          <Select
            isMulti={false}
            name="keywords"
            options={availableKeywords?.map((keyword) => ({
              label: keyword.koreaName,
              value: keyword,
            }))}
            onChange={handleKeywordChange}
            required
          />
        </label>

        <label>
          UX Tag:
          <select onChange={handleUxEvaluationTagChange} required>
            <option value="">Select Tag</option>
            {Object.entries(UX_EVALUATION).map(([key, value]) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </label>

        <label htmlFor="author">작성자:</label>
        <select
          id="author"
          value={selectedAuthor}
          onChange={handleAuthorChange}
        >
          <option value="">작성자를 선택하세요</option>
          {allAuthorsList.map((author) => (
            <option key={author.id} value={author.id}>
              {author.authorName}
            </option>
          ))}
        </select>

        <label>
          글 작성자
          <input
            type="text"
            value={authorName}
            onChange={(e) => setAuthorName(e.target.value)}
            placeholder="글 작성자를 입력하세요."
            required
          />
        </label>

        <label>
          작성자 Email
          <input
            type="email"
            value={authorEmail}
            onChange={(e) => setAuthorEmail(e.target.value)}
            placeholder="Email을 입력하세요."
          />
        </label>

        <label>
          작성자 Instagram
          <input
            type="url"
            value={authorInsta}
            onChange={(e) => setAuthorInsta(e.target.value)}
            placeholder="Instagram 링크를 입력하세요."
          />
        </label>

        <label>
          작성자 LinkedIn
          <input
            type="url"
            value={authorLinkedIn}
            onChange={(e) => setAuthorLinkedIn(e.target.value)}
            placeholder="LinkedIn 링크를 입력하세요."
          />
        </label>

        {articleLinks.map((link, index) => (
          <div key={index} className="article-link-input">
            <input
              type="url"
              value={link}
              onChange={(e) => handleArticleLinkChange(index, e.target.value)}
              placeholder="참고 링크"
            />
            {articleLinks.length > 1 && (
              <button
                type="button"
                onClick={() => handleRemoveArticleLink(index)}
              >
                X
              </button>
            )}
          </div>
        ))}
        <button type="button" onClick={handleAddArticleLink}>
          + 참고 링크 추가
        </button>
        <label>
          본문
          <textarea
            className="content-box"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="본문을 입력하세요."
            required
          />
        </label>

        {/* 이미지와 설명 세트 */}
        {images.map((imgSet, index) => (
          <div key={index} className="image-set">
            <label>
              이미지 {index + 1}
              <input
                type="url"
                value={imgSet.imageUrl}
                onChange={(e) => handleImageChange(index, e.target.value)}
                placeholder="이미지 URL"
                required
              />
            </label>
            <label>
              설명
              <input
                type="text"
                value={imgSet.description}
                onChange={(e) => handleDescriptionChange(index, e.target.value)}
                placeholder="이미지 설명 (선택 사항)"
              />
            </label>
            {images.length > 1 && (
              <button type="button" onClick={() => handleRemoveImageSet(index)}>
                X
              </button>
            )}
          </div>
        ))}
        <button type="button" onClick={handleAddImageSet}>
          + Add Image
        </button>
        {/*<label>*/}
        {/*  Category Tags:*/}
        {/*  <input*/}
        {/*    type="text"*/}
        {/*    value={categoryTag.join(", ")}*/}
        {/*    onChange={(e) =>*/}
        {/*      handleTagChange(setCategoryTag, e.target.value.split(","))*/}
        {/*    }*/}
        {/*    placeholder="category tags"*/}
        {/*  />*/}
        {/*</label>*/}
        {/*<label>*/}
        {/*  Pattern Tags:*/}
        {/*  <input*/}
        {/*    type="text"*/}
        {/*    value={patternTag.join(", ")}*/}
        {/*    onChange={(e) =>*/}
        {/*      handleTagChange(setPatternTag, e.target.value.split(","))*/}
        {/*    }*/}
        {/*    placeholder="pattern tags"*/}
        {/*  />*/}
        {/*</label>*/}
        {/*<label>*/}
        {/*  UI Component Tags:*/}
        {/*  <input*/}
        {/*    type="text"*/}
        {/*    value={uiComponentTag.join(", ")}*/}
        {/*    onChange={(e) =>*/}
        {/*      handleTagChange(setUIComponentTag, e.target.value.split(","))*/}
        {/*    }*/}
        {/*    placeholder="UI Component tags"*/}
        {/*  />*/}
        {/*</label>*/}
        <button type="submit">Add Article</button>
      </form>
    </div>
  );
};

export default ArticleForm;
