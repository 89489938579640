// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { columnDataInterface, columnDetailInterface } from "../types/type";
import { getApiClient } from "../api/client.api";
import { getArticleFromId } from "../api/articles.api";
import ShareBtn from "../components/common/ShareBtn";
import UxEvaluationTag from "../components/tag/UxEvaluationTag";
import AuthorContactBtn, {
  AuthorContactBtnProps,
} from "../components/tag/AuthorContactBtn";
import { AUTHOR_CONTACT_TYPE } from "../types/enum";
import { ShowAuthorContactBtn } from "../components/mobile/ShowAuthorContactBtn";

export default function Detail(props: columnDetailInterface) {
  // const { id } = useParams();
  const [columnData, setColumnData] = useState<
    columnDataInterface | undefined
  >();
  const url = window.location.href;
  const imgWrapperRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleArrowVisibility = () => {
      if (imgWrapperRef.current) {
        const scrollLeft = imgWrapperRef.current.scrollLeft;
        const maxScrollLeft =
          imgWrapperRef.current.scrollWidth - imgWrapperRef.current.clientWidth;

        setShowLeftArrow(scrollLeft > 0);
        setShowRightArrow(scrollLeft < maxScrollLeft);
      }
    };

    const imgWrapper = imgWrapperRef.current;
    if (imgWrapper) {
      imgWrapper.addEventListener("scroll", handleArrowVisibility);
    }

    handleArrowVisibility(); // 초기 상태 업데이트

    return () => {
      if (imgWrapper) {
        imgWrapper.removeEventListener("scroll", handleArrowVisibility);
      }
    };
  }, [columnData]);

  const handleScroll = (direction: number) => {
    if (imgWrapperRef.current) {
      const imgWrapper = imgWrapperRef.current;
      const imgElements = imgWrapper.querySelectorAll(".image-desc-wrapper");

      if (!imgElements.length) return;

      const wrapperWidth = imgWrapper.clientWidth; // 한 번에 보이는 영역의 너비
      const currentScroll = imgWrapper.scrollLeft;
      const totalWidth = imgWrapper.scrollWidth;
      // const imgWidth = imgElements[0].clientWidth; // 이미지 1개의 너비 (간격 포함)

      if (direction === 1) {
        // 오른쪽 화살표 클릭
        const nextScroll = Math.min(
          currentScroll + wrapperWidth,
          totalWidth - wrapperWidth,
        );
        imgWrapper.scrollTo({ left: nextScroll, behavior: "smooth" });
      } else {
        // 왼쪽 화살표 클릭
        const prevScroll = Math.max(currentScroll - wrapperWidth, 0);
        imgWrapper.scrollTo({ left: prevScroll, behavior: "smooth" });
      }
    }
  };

  // const handleCopyClipBoard = async () => {
  //   try {
  //     await navigator.clipboard.writeText(url);
  //     alert("클립보드에 링크가 복사되었습니다.");
  //   } catch (err) {
  //     alert("복사를 실패하였습니다. 다시 시도해주세요.");
  //     console.log(err);
  //   }
  // };

  const toggleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    const element = contentRef.current;
    if (!element) return;

    const checkOverflow = () => {
      const lineHeight = parseFloat(
        getComputedStyle(element).lineHeight || "0",
      );
      const maxHeight = lineHeight * 5;
      setIsOverflow(element.scrollHeight > maxHeight);
    };

    checkOverflow();

    const observer = new ResizeObserver(checkOverflow);
    observer.observe(element);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const apiClient = getApiClient();
    if (props.id) {
      getArticleFromId(apiClient, props.id)
        .then((res) => {
          setColumnData(res.data);
        })
        .catch(() => {
          console.log("error");
        });
    }
  }, []);

  const authorLinkForMobile: AuthorContactBtnProps[] = [
    props.data?.authorInsta && {
      btnType: AUTHOR_CONTACT_TYPE.INSTAGRAM,
      link: props.data?.authorInsta,
    },
    props.data?.authorInsta && {
      btnType: AUTHOR_CONTACT_TYPE.LINKEDIN,
      link: props.data?.authorLinkedIn,
    },
    props.data?.authorInsta && {
      btnType: AUTHOR_CONTACT_TYPE.EMAIL,
      link: props.data?.authorEmail,
    },
    {
      btnType: AUTHOR_CONTACT_TYPE.ARTICLE_LINK,
      link: props.id,
    },
  ].filter(Boolean);

  const [loadedImages, setLoadedImages] = useState<{ [key: string]: boolean }>(
    {},
  );

  const handleImageLoad = (imageUrl: string) => {
    setLoadedImages((prevState) => ({
      ...prevState,
      [imageUrl]: true,
    }));
  };

  return (
    <div className="detail-page">
      <div className="top-line">
        <div className="top-left">
          <div className="title">{columnData?.title}</div>
          <div className="title-divide-bar"></div>
          <img
            alt="product-icon"
            className="product-icon"
            src={columnData?.productImage}
          />
          <div className="product-name">{columnData?.product}</div>
          {columnData && <UxEvaluationTag name={columnData.uxEvaluation!} />}
        </div>
        <div className="top-right">
          <ShareBtn copyUrl={url} />
          <div className="close-btn" onClick={() => props.closeModal()}>
            <img
              src={`${process.env.PUBLIC_URL}` + "/assets/icons/Xbutton.svg"}
              alt="close-btn"
              className="close-btn-img"
            />
          </div>
        </div>
      </div>
      <div className="center-content">
        <div className="content-container">
          <div
            className={`content-left ${isExpanded ? "expanded" : "collapsed"}`}
            ref={contentRef}
          >
            {columnData?.content}
          </div>
          <div className="divide-line"></div>
          <div className="article-link">
            <div className="article-link-title">참고 링크</div>
            {columnData?.articleLink?.map((article, idx) => {
              return (
                <div className="article-wrapper" key={idx}>
                  <img
                    src={
                      `${process.env.PUBLIC_URL}` + "/assets/icons/LinkLogo.svg"
                    }
                    alt="close-btn"
                  />
                  <a key={idx} href={article} target="_blank" rel="noreferrer">
                    {article}
                  </a>
                </div>
              );
            })}
          </div>
          {isOverflow && (
            <button className="more-content" onClick={toggleExpand}>
              {isExpanded ? "숨기기" : "더보기"}
            </button>
          )}
          {/*<img alt="preview-img" src={props.imageSrc} />*/}
        </div>
        <div className="divide-vertical-line"></div>
        {/*<div className="tag-wrapper">*/}
        {/*  {columnData?.categoryTag?.map((el) => {*/}
        {/*    return <CategoryTag name={el} key={el} />;*/}
        {/*  })}*/}

        {/*  {columnData?.patternTag?.map((el) => {*/}
        {/*    return <PatternTag name={el} key={el} />;*/}
        {/*  })}*/}
        {/*  {columnData?.uiComponentTag?.map((el) => {*/}
        {/*    return <UiComponent name={el} key={el} />;*/}
        {/*  })}*/}
        {/*</div>*/}

        <div className="ux-evaluation-tag-mobile">
          {columnData && <UxEvaluationTag name={columnData.uxEvaluation!} />}

          <div className="img-slider-container">
            {showLeftArrow && (
              <button className="left-arrow" onClick={() => handleScroll(-1)}>
                <img
                  src={
                    `${process.env.PUBLIC_URL}` + "/assets/icons/ArrowRight.svg"
                  }
                  alt="left-btn"
                />
              </button>
            )}
            <div className="img-wrapper" ref={imgWrapperRef}>
              {columnData?.images?.map((el, idx) => {
                return (
                  <div className="image-desc-wrapper" key={el.imageUrl! + idx}>
                    {!loadedImages[el.imageUrl] && <div className="spinner" />}
                    <img
                      src={el.imageUrl}
                      alt={`${idx}번째 설명 이미지`}
                      loading="lazy"
                      onLoad={() => handleImageLoad(el.imageUrl)}
                      className={`screen-image ${loadedImages[el.imageUrl] ? "visible" : "hidden"}`}
                    />
                    {el.description && (
                      <div className="img-desc-box">
                        <div className="img-desc-title-box">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/icons/FiUxInsight.svg`}
                            alt="UX 인사이트 타이틀 아이콘"
                            className="ux-insight-icon"
                          />
                          <div className="insight-notes-title">UX 인사이트</div>
                        </div>
                        <div className="desc">{el.description}</div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {showRightArrow && (
              <button className="right-arrow" onClick={() => handleScroll(1)}>
                <img
                  src={
                    `${process.env.PUBLIC_URL}` + "/assets/icons/ArrowRight.svg"
                  }
                  alt="left-btn"
                />
              </button>
            )}
          </div>
        </div>
        <div className="article-link-container-for-mobile">
          {/*<div className="divide-line"></div>*/}
          <div className="article-link">
            <div className="article-link-title">참고 링크</div>
            {columnData?.articleLink?.map((article, idx) => {
              return (
                <div className="article-wrapper" key={idx}>
                  <img
                    src={
                      `${process.env.PUBLIC_URL}` + "/assets/icons/LinkLogo.svg"
                    }
                    alt="close-btn"
                  />
                  <a key={idx} href={article} target="_blank" rel="noreferrer">
                    {article}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="author-wrapper">
        <div className="author-title">
          <div className="author-text">작성자</div>
          <div className="author-name">{columnData?.author}</div>
        </div>
        <div className="author-contact-box">
          {columnData?.authorEmail !== "" ? (
            <AuthorContactBtn
              btnType={AUTHOR_CONTACT_TYPE.EMAIL}
              link={columnData?.authorEmail}
            />
          ) : null}
          {columnData?.authorInsta !== "" ? (
            <AuthorContactBtn
              btnType={AUTHOR_CONTACT_TYPE.INSTAGRAM}
              link={columnData?.authorInsta}
            />
          ) : null}
          {columnData?.authorLinkedIn !== "" ? (
            <AuthorContactBtn
              btnType={AUTHOR_CONTACT_TYPE.LINKEDIN}
              link={columnData?.authorLinkedIn}
            />
          ) : null}
        </div>

        <div className="author-contact-mobile-box">
          <ShowAuthorContactBtn
            key={props.id}
            keyId={props.id}
            btnType={authorLinkForMobile}
          />
        </div>
      </div>
    </div>
  );
}
