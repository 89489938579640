import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

type CategorySectionProps = {
  title: string;
  items: { id: string; name: string; count: number }[];
  filterType: "keyword" | "product" | "uxEvaluation";
  keywordUniqueId?: string;
  expanded: boolean;
  toggleExpand?: () => void;
  handleFilterChange: (
    filterType: string,
    id: string,
    checked: boolean,
  ) => void;
  handleDivClick: (e: React.MouseEvent, id: string) => void;
};

export default function CategorySection({
  title,
  items,
  filterType,
  keywordUniqueId,
  expanded,
  toggleExpand,
  handleFilterChange,
  handleDivClick,
}: CategorySectionProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [bigCategoryState, setBigCategoryState] = useState<
    "checked" | "indeterminate" | "unchecked"
  >("unchecked");

  // 🔥 `big-category`의 체크 상태 업데이트 (모두 선택 / 일부 선택 / 해제)
  useEffect(() => {
    const selectedItems =
      items &&
      items.filter((el) =>
        searchParams.getAll(filterType).includes(`${filterType}_${el.id}`),
      );

    if (selectedItems && selectedItems.length === items.length) {
      setBigCategoryState("checked"); // ✅ 모두 선택됨
    } else if (selectedItems && selectedItems.length > 0) {
      setBigCategoryState("indeterminate"); // ➖ 일부 선택됨
    } else {
      setBigCategoryState("unchecked"); // ⬜ 아무것도 선택 안됨
    }
  }, [searchParams, items, filterType]);

  // 🔥 `big-category` 체크/해제 기능 (전체 선택 / 전체 해제)
  const handleBigCategoryChange = () => {
    const newState = bigCategoryState === "checked" ? "unchecked" : "checked";
    const newParams = new URLSearchParams(searchParams);

    if (newState === "checked") {
      // ✅ 모든 small-category 추가 (중복 방지)
      items.forEach((el) => {
        const key = `${filterType}_${el.id}`;
        if (!newParams.getAll(filterType).includes(key)) {
          newParams.append(filterType, key);
        }
      });
    } else {
      // ⬜ 모든 small-category 제거
      const selectedItems = newParams
        .getAll(filterType)
        .filter(
          (value) => !items.some((el) => value === `${filterType}_${el.id}`),
        );
      newParams.delete(filterType); // 기존 값 삭제
      selectedItems.forEach((value) => newParams.append(filterType, value)); // 남은 값만 다시 추가
    }

    setSearchParams(newParams);
  };

  return (
    <div className="category-box">
      <div className="big-category">
        <input
          type="checkbox"
          id={
            keywordUniqueId
              ? `big-${filterType}-${keywordUniqueId}`
              : `big-${filterType}`
          }
          checked={bigCategoryState === "checked"}
          ref={(input) => {
            if (input)
              input.indeterminate = bigCategoryState === "indeterminate";
          }}
          onChange={handleBigCategoryChange}
        />
        <label
          htmlFor={
            keywordUniqueId
              ? `big-${filterType}-${keywordUniqueId}`
              : `big-${filterType}`
          }
          className={
            bigCategoryState === "indeterminate" ? "indeterminate" : ""
          }
        ></label>
        <div className="big-category">☠️ {title}</div>
        {/*<div className="category-name">{title}</div>*/}
      </div>
      <div className="small-category-list">
        {items &&
          items.slice(0, expanded ? items.length : 7).map((el) => (
            <div
              key={el.id}
              className="small-category"
              onClick={(e) => handleDivClick(e, el.id)}
            >
              <input
                type="checkbox"
                id={el.id}
                checked={searchParams
                  .getAll(filterType)
                  .includes(`${filterType}_${el.id}`)}
                onChange={(e) =>
                  handleFilterChange(filterType, el.id, e.target.checked)
                }
              />
              <label htmlFor={el.id}></label>
              <div className="category-name">{el.name}</div>
              <div className="number-of-post">{el.count}</div>
            </div>
          ))}
      </div>
      {items && items.length > 7 && (
        <button className="more-content" onClick={toggleExpand}>
          {expanded ? "숨기기" : "더보기"}
        </button>
      )}
      <div className="divide-line"></div>
    </div>
  );
}
