import { columnPreviewInterface } from "../../types/type";
import UxEvaluationTag from "../../components/tag/UxEvaluationTag";
import AuthorContactBtn, {
  AuthorContactBtnProps,
} from "../tag/AuthorContactBtn";
import { AUTHOR_CONTACT_TYPE } from "../../types/enum";
import { ShowAuthorContactBtn } from "../mobile/ShowAuthorContactBtn";
import { useEffect, useRef, useState } from "react";

export default function ColumnPreview(props: columnPreviewInterface) {
  const authorLinkForMobile: AuthorContactBtnProps[] = [
    props.authorInsta && {
      btnType: AUTHOR_CONTACT_TYPE.INSTAGRAM,
      link: props.authorInsta,
    },
    props.authorLinkedIn && {
      btnType: AUTHOR_CONTACT_TYPE.LINKEDIN,
      link: props.authorLinkedIn,
    },
    props.authorEmail && {
      btnType: AUTHOR_CONTACT_TYPE.EMAIL,
      link: props.authorEmail,
    },
    {
      btnType: AUTHOR_CONTACT_TYPE.ARTICLE_LINK,
      link: props.id,
    },
  ].filter(Boolean);
  const url = window.location.href + "detail/" + `${props.id}`;

  const onClickDetail = () => {
    props.onClickDetail(props.id);
    props.setIsOpened(true);
    window.history.pushState(null, "", url);
  };

  const [loadedImages, setLoadedImages] = useState<{ [key: string]: boolean }>(
    {},
  );

  const handleImageLoad = (imageUrl: string) => {
    setLoadedImages((prevState) => ({
      ...prevState,
      [imageUrl]: true,
    }));
  };

  const imgRefs = useRef<(HTMLImageElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target as HTMLImageElement;
            img.src = img.dataset.src!;
            observer.unobserve(img);
          }
        });
      },
      {
        rootMargin: "50px",
        threshold: 0.1,
      },
    );

    imgRefs.current.forEach((img) => {
      if (img) observer.observe(img);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="column-component" onClick={onClickDetail}>
      <div className="top-line">
        <div className="title">{props.title}</div>
        {/*</Link>*/}
        <div className="title-divide-bar"></div>
        <img
          alt="product-icon"
          className="product-icon"
          src={props.productImage}
        />
        <div className="product-name">{props.product}</div>
        <UxEvaluationTag name={props.uxEvaluation!} />
      </div>
      <div className="content-container">
        <div className="content-wrapper">
          <UxEvaluationTag name={props.uxEvaluation!} />

          <div className="img-wrapper">
            {props.imageSrc?.map((el, idx) => (
              <div className="img-container" key={el.imageUrl + idx}>
                {!loadedImages[el.imageUrl] && <div className="spinner" />}
                <img
                  alt="preview-img"
                  data-src={el.imageUrl.replace(/\.(jpg|png|avif)$/, ".webp")}
                  ref={(el) => (imgRefs.current[idx] = el)}
                  onLoad={() => handleImageLoad(el.imageUrl)}
                  className={`preview-img ${loadedImages[el.imageUrl] ? "visible" : "hidden"}`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="author-wrapper">
        <div className="author-title">
          <div className="author-text">작성자</div>
          <div className="author-name">{props.author}</div>
        </div>
        <div className="author-contact-box">
          {props.authorEmail !== "" ? (
            <AuthorContactBtn
              btnType={AUTHOR_CONTACT_TYPE.EMAIL}
              link={props.authorEmail}
            />
          ) : null}
          {props.authorInsta !== "" ? (
            <AuthorContactBtn
              btnType={AUTHOR_CONTACT_TYPE.INSTAGRAM}
              link={props.authorInsta}
            />
          ) : null}
          {props.authorLinkedIn !== "" ? (
            <AuthorContactBtn
              btnType={AUTHOR_CONTACT_TYPE.LINKEDIN}
              link={props.authorLinkedIn}
            />
          ) : null}
        </div>
        <div className="author-contact-mobile-box">
          <ShowAuthorContactBtn
            key={props.id}
            keyId={props.id}
            btnType={authorLinkForMobile}
          />
        </div>
      </div>
    </div>
  );
}
