import { useEffect, useRef, useState } from "react";
import FilterTag from "../../components/tag/FilterTag";
import { KeywordData, ProductsData, UxEvaluationCount } from "../../types/type";
import { useSearchParams } from "react-router-dom";
import { getKeywords } from "../../api/keywords.api";
import { getApiClient } from "../../api/client.api";

type KeywordFilterProps = {
  products: ProductsData[];
  uxEvaluations: UxEvaluationCount[];
  onReset: () => void;
};

export default function FilteredSearchComponent({
  products = [],
  uxEvaluations,
  onReset,
}: KeywordFilterProps) {
  const apiClient = getApiClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredTags, setFilteredTags] = useState<
    { type: string; id: string; name: string }[]
  >([]);
  const [keywords, setKeywords] = useState<KeywordData[] | undefined>();
  const [isFixed, setIsFixed] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, left: 0 });
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    getKeywords(apiClient).then((res) => {
      setKeywords(res.data);
    });
  }, []);

  useEffect(() => {
    const updateDimensions = () => {
      if (wrapperRef.current) {
        const parent = wrapperRef.current.parentElement;
        if (parent) {
          const { width, left } = parent.getBoundingClientRect();
          setDimensions({ width, left });
        }
      }
    };

    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 150; // 특정 스크롤 위치 설정

      if (scrollY > threshold) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    // 초기 크기 설정 및 리사이즈 감지
    updateDimensions();
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  useEffect(() => {
    const allProductIds = searchParams.getAll("product");
    const allKeywordIds = searchParams.getAll("keyword");
    const allEvaluations = searchParams.getAll("uxEvaluation");

    const selectedProducts = allProductIds
      .map((id) => {
        const product = products?.find((p) => `product_${p.id}` === id);
        return product
          ? { type: "product", id: product.id, name: product.name }
          : null;
      })
      .filter(Boolean);

    const selectedKeywords = allKeywordIds
      .map((id) => {
        const keyword = keywords?.find((k) => `keyword_${k.id}` === id);
        return keyword
          ? {
              type: "keyword",
              id: keyword.id,
              name: keyword.koreaName,
            }
          : null;
      })
      .filter(Boolean);

    const selectedEvaluations = allEvaluations
      .map((id) => {
        const evaluation = uxEvaluations?.find(
          (p) => `uxEvaluation_${p.tagId}` === id,
        );
        return evaluation
          ? {
              type: "uxEvaluation",
              id: evaluation.tagId,
              name: evaluation.tagName,
            }
          : null;
      })
      .filter(Boolean);

    setFilteredTags([
      ...(selectedProducts.filter(
        (product) => product?.id && product?.name,
      ) as { type: string; id: string; name: string }[]),
      ...(selectedKeywords.filter(
        (keyword) => keyword?.id && keyword?.name,
      ) as { type: string; id: string; name: string }[]),
      ...(selectedEvaluations.filter(
        (evaluation) => evaluation?.id && evaluation?.name,
      ) as { type: string; id: string; name: string }[]),
    ]);
  }, [searchParams, products, keywords, uxEvaluations]);

  const handleRemoveFilter = (type: string, id: string) => {
    const paramName = `${type}_${id}`;
    const updatedParams = searchParams
      .getAll(type)
      .filter((param) => param !== paramName);

    searchParams.delete(type);
    updatedParams.forEach((param) => searchParams.append(type, param));

    setSearchParams(searchParams);
  };

  const resetFilters = () => {
    setFilteredTags([]); // 상태 초기화
    window.history.replaceState({}, "", `${window.location.pathname}`);
    onReset();
  };

  return (
    <div
      ref={wrapperRef}
      className={`filtered-search-wrapper ${isFixed ? "fixed" : ""}`}
      style={
        {
          "--dynamic-width": `${dimensions.width}px`,
          "--dynamic-left": `${dimensions.left}px`,
        } as React.CSSProperties
      }
    >
      <div className="reset-container" onClick={resetFilters}>
        <img
          alt="reset"
          src={`${process.env.PUBLIC_URL}` + "/assets/icons/ClockClockwise.svg"}
        />
        <div>초기화</div>
      </div>
      <div className="filtered-container">
        {filteredTags.map((tag) => (
          <FilterTag
            key={`${tag.type}_${tag.id}`}
            name={tag.name}
            onRemove={() => handleRemoveFilter(tag.type, tag.id)}
          />
        ))}
      </div>
    </div>
  );
}
