import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  ExpandedCategories,
  KeywordsWithCategoryData,
  ProductsData,
  UxEvaluationCount,
} from "../../types/type";
import CategorySection from "./CategorySection";

type KeywordFilterProps = {
  keywordsData: KeywordsWithCategoryData[];
  products: ProductsData[];
  uxEvaluationsCount: UxEvaluationCount[];
  onReset: boolean;
  setOnResetTriggered: (state: boolean) => void;
  isMobile: boolean;
  isMobileMenuOpen: boolean;
  closeMobileMenu: () => void;
};

export default function KeywordFilter({
  keywordsData,
  products,
  uxEvaluationsCount,
  onReset,
  setOnResetTriggered,
  isMobile,
  isMobileMenuOpen,
  closeMobileMenu,
}: KeywordFilterProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [expandedCategories, setExpandedCategories] =
    useState<ExpandedCategories>({});
  const [isExpandedProduct, setIsExpandedProduct] = useState(false);

  useEffect(() => {
    if (onReset) {
      setSearchParams(new URLSearchParams());
      setOnResetTriggered(false);
    }
  }, [onReset, setSearchParams, setOnResetTriggered]);

  const toggleExpandCategory = (categoryId: string) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }));
  };

  const toggleExpandProduct = () => {
    setIsExpandedProduct((prev) => !prev);
  };

  const handleFilterChange = (
    filterType: "keyword" | "product" | "uxEvaluation",
    id: string,
    checked: boolean,
  ) => {
    const paramName = `${filterType}_${id}`;

    if (checked) {
      searchParams.append(filterType, paramName);
    } else {
      const paramsToRemove = searchParams.getAll(filterType);
      searchParams.delete(filterType);

      paramsToRemove.forEach((param) => {
        if (param !== paramName) {
          searchParams.append(filterType, param);
        }
      });
    }

    setSearchParams(searchParams);
  };

  const handleDivClick = (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    document.getElementById(id)?.click();
  };

  return (
    <div
      className={`keyword-filter ${isMobile ? "mobile" : ""} ${isMobileMenuOpen ? "open" : ""}`}
    >
      <div className="menu-title">
        <div className="title-text">UX 키워드 필터링</div>
        <div className="done-btn" onClick={closeMobileMenu}>
          완료
        </div>
      </div>
      <div className="for-padding-top"></div>

      {/* UX 분석 필터 */}
      <CategorySection
        title="UX 분석"
        items={
          uxEvaluationsCount &&
          uxEvaluationsCount.map((el) => ({
            id: el.tagId,
            name: el.tagName,
            count: el.count,
          }))
        }
        filterType="uxEvaluation"
        expanded={false}
        handleFilterChange={handleFilterChange}
        handleDivClick={handleDivClick}
      />

      {/* 프로덕트 필터 */}
      <CategorySection
        title="프로덕트"
        items={
          products &&
          products.map((el) => ({
            id: el.id,
            name: el.name,
            count: el.numOfArticles,
          }))
        }
        filterType="product"
        expanded={isExpandedProduct}
        toggleExpand={toggleExpandProduct}
        handleFilterChange={handleFilterChange}
        handleDivClick={handleDivClick}
      />

      {/* UX 키워드 필터 */}
      {keywordsData &&
        keywordsData.map((category) => {
          const sortedKeywords = [...category.keywords].sort((a, b) =>
            a.keywordKorName > b.keywordKorName ? 1 : -1,
          );
          return (
            <CategorySection
              key={category.categoryId}
              title={category.categoryKorName}
              items={sortedKeywords.map((kw) => ({
                id: kw.keywordId,
                name: kw.keywordKorName,
                count: kw.numOfArticles,
              }))}
              filterType="keyword"
              keywordUniqueId={`keyword-${category.categoryId}`}
              expanded={expandedCategories[category.categoryId!] || false}
              toggleExpand={() => toggleExpandCategory(category.categoryId!)}
              handleFilterChange={handleFilterChange}
              handleDivClick={handleDivClick}
            />
          );
        })}
    </div>
  );
}
